import Footer from 'components/Footer'
import Header from 'components/Header'
import QueryString from 'qs'
import { ReactNode } from 'react'
import { useAuth } from 'shared/contexts/Auth'

interface IIWithHeader {
  children: ReactNode
  kdcDetailPage?: boolean
}

const WithHeader = ({ children, kdcDetailPage = false }: IIWithHeader) => {
  const { isSigned, signInUrl, profile } = useAuth()
  const state = QueryString.stringify({ state: location.pathname })
  const signInUrlWithState = `${signInUrl}&${state}`
  return (
    <>
      <div className="relative">
        <Header isSigned={isSigned} signInUrl={signInUrlWithState} profile={profile} />
        <div className="flex-1">{children}</div>
        {!kdcDetailPage && (
          <div className="shrink-0">
            <Footer />
          </div>
        )}
      </div>
    </>
  )
}

export default WithHeader
