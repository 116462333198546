import 'dayjs/locale/ko'

import { useMediaQuery } from '@mantine/hooks'
import dayjs from 'dayjs'
import HomeApplyPeriodBadge from 'features/klass/components/HomeApplyPeriodBadge'
import _ from 'lodash'
import Image from 'next/image'
import React, { useCallback } from 'react'
import { ICourse } from 'stores/courses'

import styles from './KDTKlassCard.module.scss'

dayjs.locale('ko')

interface IKDTKlassProps {
  course: ICourse
  handleRouter(course: ICourse, courseId: string): void
}

const HomeKDTCard = ({ course, handleRouter }: IKDTKlassProps) => {
  const { id, title, subtitle, image, imageM, applyStartedAt, applyEndedAt } = course
  const isMobile = useMediaQuery('(max-width: 767px)')
  const startDay = isMobile
    ? dayjs(applyStartedAt).format('YY.MM.DD')
    : dayjs(applyStartedAt).format('YY.MM.DD(ddd)')
  const endDay = isMobile
    ? dayjs(applyEndedAt).format('YY.MM.DD')
    : dayjs(applyEndedAt).format('YY.MM.DD(ddd)')

  const goCourseDetail = useCallback(() => {
    handleRouter(course, id)
  }, [course, handleRouter, id])

  const curDate = dayjs()
  const isBeforeApplyEnd = dayjs(curDate).isBefore(applyEndedAt)

  const isTablet = useMediaQuery('(max-width: 1023px)')
  const schoolTitleNoNumber = title.replaceAll(/[0-9]/g, '')
  const schoolTitle = schoolTitleNoNumber.replaceAll(/[기]/g, '')

  return (
    <li
      className={`cursor-pointer ${isTablet ? '' : styles.HoverImgScale}`}
      onClick={goCourseDetail}>
      <div className="relative flex h-full w-full shrink-0 flex-col rounded-lg">
        {image && (
          <div
            className="
          relative aspect-square shrink-0 overflow-hidden rounded md:rounded-lg lg:aspect-[290/221]">
            <Image
              src={isTablet ? imageM : image}
              alt={title}
              className="h-[312px] w-[312px] lg:h-[221px] lg:w-[290px]"
              width={290}
              height={221}
              layout="fill"
              objectFit="cover"
              sizes="290px"
              priority={true}
            />
          </div>
        )}
        <div className="box-border flex h-full w-full flex-col pt-3 md:pt-4 lg:ml-0">
          <HomeApplyPeriodBadge size="md" startedAt={applyStartedAt} endedAt={applyEndedAt} />
          <p className={`${styles.schoolTitle} mb-2 mt-4 text-base font-semibold md:text-xl`}>
            {schoolTitle}
          </p>
          <span
            className={`block text-xs font-medium md:text-base lg:text-base lg:font-normal xl:text-lg ${
              !isBeforeApplyEnd ? 'mb-2' : ''
            }`}
            style={{ wordBreak: 'keep-all' }}>
            {subtitle}
          </span>
          {isBeforeApplyEnd && (
            <span className="mt-2 block text-xs font-normal text-gray-400 md:mt-4 md:text-base">
              {startDay} ~ {endDay} 까지
            </span>
          )}
        </div>
      </div>
    </li>
  )
}
export default HomeKDTCard
