import dayjs from 'dayjs'
import { IKlass } from 'features/klass/types'

export const sortedList = (klassList: IKlass[], sortType: string) => {
  if (sortType === 'early') {
    return klassList
      .filter(klass => dayjs().isBefore(klass.applyStartedAt))
      .sort((a, b) => dayjs(a.applyStartedAt).valueOf() - dayjs(b.applyStartedAt).valueOf())
  } else if (sortType === 'apply') {
    return klassList
      .filter(
        klass => dayjs().isAfter(klass.applyStartedAt) && dayjs().isBefore(klass.applyEndedAt),
      )
      .sort((a, b) => dayjs(a.applyEndedAt).valueOf() - dayjs(b.applyEndedAt).valueOf())
  } else {
    return klassList.filter(klass => dayjs().isAfter(klass.applyEndedAt))
  }
}
