import TagManager from 'react-gtm-module'
import { ICourse } from 'stores/courses'

export const onSelectKdtItem = (course: ICourse) => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'KDT_item_click',
      content_ids: course.id,
      ...(sessionStorage.getItem('utmAndGclid') &&
        JSON.parse(sessionStorage.getItem('utmAndGclid') as string)),
    },
  })
}
