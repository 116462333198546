import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import _ from 'lodash'
import { useMemo } from 'react'

import { checkApplyPeriod } from '../utils/checkApplyPeriod'

dayjs.extend(relativeTime)

interface IApplyPeriodBadgeProps {
  startedAt: string
  endedAt: string
  size: 'sm' | 'md'
}

const HomeApplyPeriodBadge = ({ size, startedAt, endedAt }: IApplyPeriodBadgeProps) => {
  const status = checkApplyPeriod(startedAt, endedAt)
  const { label, style } = applyStatusList[status]
  const nowDate = dayjs().format('YYYY-MM-DD')
  const endedDate = dayjs(endedAt).format('YYYY-MM-DD')
  const expirationDate = useMemo(() => dayjs(endedDate).diff(nowDate, 'day'), [endedAt])
  const isSm = _.isEqual(size, 'sm')
  return (
    <div className={`${isSm ? 'mt-2' : ''}`}>
      <div
        className={`${style} inline-flex items-center rounded border px-2 py-1 text-xs font-semibold sm:text-sm`}>
        {label}
        {_.isEqual(status, 'applyDeadline') &&
          ` D-${_.isEqual(expirationDate, 0) ? 'day' : expirationDate}`}
      </div>
    </div>
  )
}

export interface IApplyStatusList {
  [status: string]: {
    label: string
    style: string
  }
}

const applyStatusList: IApplyStatusList = {
  early: {
    label: '사전알림신청',
    style: 'border-teal-500 text-emerald-600',
  },
  apply: {
    label: '모집중',
    style: 'border-blue-500 text-blue-700',
  },
  applyDeadline: {
    label: '마감임박',
    style: 'border-blue-500 text-blue-700',
  },
  applyEnd: {
    label: '모집마감',
    style: 'border-gray-500 text-gray-650',
  },
}

export default HomeApplyPeriodBadge
