import _ from 'lodash'
import { useRouter } from 'next/router'
import { useCallback } from 'react'
import { onSelectKdtItem } from 'shared/gtm/onSelectKdtItem'
import { ICourse } from 'stores/courses'

import HomeKDTCard from './HomeKDTCard'

interface IKDTKlassProps {
  course: ICourse
}

const KDTKlassCard = ({ course }: IKDTKlassProps) => {
  const router = useRouter()
  const handleRouter = useCallback(
    (course: ICourse, courseId: string) => {
      // gtm 전자상거래
      if (router.pathname.includes('/school')) {
        onSelectKdtItem(course)
      }
      return router.push(`/school/${courseId}`).then(() => window.scrollTo(0, 0))
    },
    [router],
  )
  return <HomeKDTCard course={course} handleRouter={handleRouter} />
}

export default KDTKlassCard
