import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import QueryString from 'qs'
import { convertCaseList, ConvertType } from 'utils/convertString'

import { IShowRoom, IShowRoomResponse } from '../types/showroom'

export const fetchShowRoomList = async (location: string, section: string, key?: string) => {
  const requestQueryString = QueryString.stringify({
    ...(key
      ? { filters: `location:${location}|section:${section}|filter_key:${key}` }
      : { filters: `location:${location}|section:${section}` }),
  })

  const resp = await axios.get<IShowRoomResponse[]>(
    `${window.apiEndpoint}/course/v1/showrooms?${requestQueryString}`,
  )

  return convertCaseList(resp.data, ConvertType.CAMEL) as IShowRoom[]
}

export const useShowroomQuery = (location: string, section: string, key?: string) => {
  return useQuery({
    queryKey: ['showroom', section],
    queryFn: () => fetchShowRoomList(location, section, key),
    staleTime: 60 * 60 * 1000,
    cacheTime: 60 * 60 * 1000,
  })
}
